<template>
  <div class="contentn">
    <div class="financial">
      <div class="top_Content">
        <div class="title">
          <div class="parentLevel">
            <div class="parentitle">服务类型：</div>
            <div class="parentLevel_content">
              <div
                @click="parentClick(-1)"
                :class="active == -1 ? 'one' : 'paritem'"
              >
                全部
              </div>
              <div
                v-for="(item, index) in parentLevel"
                :key="index"
                @click="parentClick(index, item)"
                :class="active == index ? 'one' : 'paritem'"
              >
                {{ item.dictValue }}
              </div>
            </div>
          </div>
          <!-- <div class="sublevel">
            <div
              v-for="(item, index) in subList"
              :key="index"
              @click="subClick(index)"
              :class="actives == index ? 'subcolor' : ''"
            >
              {{ item.title }}
            </div>
          </div> -->
        </div>
        <div class="finaBtn">
          <el-button
            type="primary"
            @click="facilitator(1)"
            v-if="status.applyStatus != 1"
            >成为服务商</el-button
          >
          <el-button type="warning" @click="facilitator(2)">发布服务</el-button>
        </div>
      </div>
      <!-- <div v-if="config.dictId == '506'" class="count">
        <div><img src="../../assets/home_img/1.png" alt="" /></div>
        <div class="count_btn">
          <el-button @click="railwayClick(1)">铁路运价试算</el-button>
          <el-button @click="railwayClick(2)">公路运价试算</el-button>
        </div>
      </div> -->

      <!-- content -->
      <div class="fiancial_content" v-if="itemList.length > 0">
        <div
          :class="ItemIndex == index ? 'contents_on' : 'content_item'"
          v-for="(item, index) in itemList"
          :key="index"
          @click="ItemClick(index, item)"
          v-if="item.serviceGoodId != '61012896765967'"
        >
          <img
            :src="baseUrl + 'third/third/ossGetFile/' + item.mainGraph"
            alt=""
          />
          <div class="item_conten">
            <div class="mony">
              ￥<span style="font-size: 24px">{{
                item.price == null ? "面议" : item.price + "/" + item.unit
              }}</span>
            </div>
            <div class="name">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="nodataImg" v-else>
        <img src="../../assets/zxx_img/nodata.png" alt="" />
      </div>
      <div class="pagination_on" v-if="itemList.length > 0">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleSizeChange"
          :current-page="config.pageNum"
          :page-size="config.pageSize"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  listByDictType,
  serviceGoodpage,
  getServiceProviderStatus,
} from "@/api/public";
const { baseUrl } = require("@/config/index.js");
export default {
  data() {
    return {
      refTitle: "title",
      baseUrl: baseUrl,
      status: {},
      active: -1,
      actives: 0,
      parentLevel: [],
      subList: [
        { title: "工商注册" },
        { title: "资料变更" },
        { title: "商标专利" },
        { title: "财务咨询" },
        { title: "代开发票" },
        { title: "代理记账" },
      ],
      clickType: 0,
      itemList: [],
      ItemIndex: 0,
      total: 0,
      config: {
        pageNum: 1,
        pageSize: 8,
        applyStatus: 1,
        companyId: null,
        dictId: null,
        userId: null,
        companyName: "",
        name: "",
        onShelf: true,
      },
    };
  },
  created() {
    if (this.$route.query) {
      this.clickType = this.$route.query.type;
    }
  },

  mounted() {
    this.getparentLevel();
    this.getpage(this.config);
    this.getstatus();
  },
  methods: {
    railwayClick(e) {
      this.$router.push({
        path: "/railway",
        query: {
          type: e,
        },
      });
    },
    handleSizeChange(val) {
      this.config.pageNum = val;
      this.getpage(this.config);
    },
    getstatus() {
      getServiceProviderStatus().then((res) => {
        if (res.code == 0) {
          console.log(res.data);
          this.status = res.data;
        }
      });
    },
    ItemClick(index, item) {
      this.$router.push({
        path: "/financialDetails?serviceGoodId=" + item.serviceGoodId,
        title: "详情",
      });
      this.ItemIndex = index;
    },

    getpage(config) {
      serviceGoodpage(config).then((res) => {
        if (res.code == 0) {
          let list = res.data.list;
          for (let i = 0; i < list.length; i++) {
            if (list[i].unit == "") {
              list[i].unit = "元";
            }
          }
          this.itemList = res.data.list;
          this.total = res.data.total;
          this.config.pageNum = res.data.pageNum;
        }
      });
    },
    getparentLevel() {
      let dictType = "service_range";
      listByDictType(dictType).then((res) => {
        if (res.code == 0) {
          this.parentLevel = res.data;

          // 首页跳转参数 详情请见created中的附值
          if (this.clickType && this.clickType != 0) {
            this.active = this.clickType;
            let temp = this.parentLevel.filter(
              (item) => item.dictValue == "物流服务"
            );
            if (temp.length > 0) {
              this.parentClick(this.clickType, temp[0]);
            }
          }
        }
      });
    },

    parentClick(index, item) {
      console.log(item);
      this.active = index;
      this.actives = 0;
      if (index == -1) {
        this.config.dictId = null;
      } else {
        this.config.dictId = item.dictId;
      }
      console.log(this.config.dictId);
      this.getpage(this.config);
    },
    subClick(index) {
      this.actives = index;
    },
    facilitator(index) {
      if (index == 1) {
        if (!this.beforhandle("serviceHall:toBeProvider")) return;
        if (this.status.applyStatus == 2 || this.status.applyStatus == null) {
          this.$router.push({
            path: "/addfacilitator",
            title: "成为服务商",
          });
        } else if (this.status.applyStatus == 1) {
          this.$message.error("您已是服务商");
        }
      } else {
        if (!this.beforhandle("serviceHall:publish")) return;
        if (this.status.applyStatus == 1) {
          this.$router.push({
            path:
              "/issueServe?serviceProviderId=" + this.status.serviceProviderId,
            title: "发布服务",
          });
        } else {
          this.$message.error("请先成为服务商");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contentn {
  width: 100%;
  height: 100%;
  background: #f7f7f7;
  .financial {
    width: 65%;
    min-width: 1200px;
    margin: 14px auto;
    background: #fff;
    padding: 20px 0;
    border: 1px solid #e5e5e5;
    .top_Content {
      display: flex;
      justify-content: space-between;
      margin: 22px 30px;
      // align-items: end;
      .title {
        .parentLevel {
          display: flex;
          font-size: 14px;
          line-height: 30px;
          .parentitle {
            width: 100px;
            line-height: 30px;
            // border-bottom: 1px solid #ccc;
          }
        }
        .sublevel {
          padding: 10px 80px;
          display: flex;
          //   padding-left: 80px;
          border: 1px solid #ccc;
          border-top: none;

          div {
            margin-left: 20px;
          }
        }
        .parentLevel_content {
          display: flex;
          font-weight: bolder;
          font-size: 14px;
          // height: 30px;
          line-height: 30px;
          flex-wrap: wrap;
          justify-content: space-between;
          .paritem {
            // border-bottom: 1px solid #ccc;
            // margin-left: 20px;
            width: 140px;
            text-align: center;
          }
        }
      }
    }
  }
}
.fiancial_content {
  display: flex;
  flex-wrap: wrap;
  padding: 0 23px 18px 23px;
  .content_item {
    margin-left: 24px;
    margin-top: 20px;
    width: 271px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 10px;

    .item_conten {
      padding: 20px 12px;
      // background: #019cff;
      .mony {
        color: #fe7b35;
        font-size: 18px;
      }
      .name {
        font-size: 12px;
        margin-top: 19px;
      }
    }
  }

  .contents_on {
    width: 271px;
    margin-top: 20px;
    background: #ffffff;
    margin-left: 24px;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    .item_conten {
      padding: 20px 12px;
      background: #019cff;
      .mony {
        color: white;
        font-size: 18px;
      }
      .name {
        font-size: 12px;
        color: white;
        margin-top: 19px;
      }
    }
  }
  img {
    width: 269px;
    height: 152px;
  }
}
.pagination_on {
  width: 100%;
  text-align: right;
}
.subcolor {
  color: #44a7fc;
}
.one {
  border: 1px solid #378eef;
  width: 140px;
  color: #378eef;
  font-weight: bold;
  text-align: center;
}
.finaBtn {
  padding-left: 30px;
  min-width: 300px;
  button {
    width: 122px;
  }
}
.nodataImg {
  padding: 100px 0;
  text-align: center;
}
.count {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  border: 1px solid #ccc;
}
.count_btn {
  margin: 10px auto;
}
</style>
